<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Client Portal <span>Footer Setting</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="playbook-header-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <div class="section_content w-100 mb-5">
                            <label for="has-login-footer" class="switch_option capsule_btn p-0">
                                <h5 class="large">Display Footer</h5>
                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-show="form.display_footer" class="section_content w-100">
                            <div class="color_container mt-2">
                                <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                    <h5 class="large">Facebook</h5>
                                    <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="form_grp p-0" v-if="form.has_facebook">
                                    <div class="group_item">
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_facebook }">
                                            <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                        </div>
                                        <ErrorMessage name="footer_facebook" class="text-danger" />
                                    </div>
                                </div>
                                <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                    <h5 class="large">Twitter</h5>
                                    <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="form_grp p-0" v-if="form.has_twitter">
                                    <div class="group_item">
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_twitter }">
                                            <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                        </div>
                                        <ErrorMessage name="footer_twitter" class="text-danger" />
                                    </div>
                                </div>
                                <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                    <h5 class="large">Instagram</h5>
                                    <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="form_grp" v-if="form.has_instagram">
                                    <div class="group_item">
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_instagram }">
                                            <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                        </div>
                                        <ErrorMessage name="footer_instagram" class="text-danger" />
                                    </div>
                                </div>
                                <div class="group_item mt-3">
                                    <label class="input_label">Icons Color</label>
                                    <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                        <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                    </Field>
                                    <ErrorMessage name="footer_textcolor" class="text-danger" />
                                </div>
                                <div class="group_item mt-3">
                                    <label class="input_label">Background Color</label>
                                    <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                        <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                    </Field>
                                    <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div v-show="form.display_footer" class="section_content w-100">
                            <div class="color_container mt-2">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Copyright Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_company }">
                                            <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" placeholder="© 2022 - Superfit Coaching" />
                                        </div>
                                        <ErrorMessage name="footer_company" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Terms Link</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_terms }">
                                            <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="footer_terms" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Privacy Link</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_policy }">
                                            <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="footer_policy" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Playbook Footer Setting',

        data () {
            return {
                form: {
                    display_footer: 0,
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    footer_textcolor: '#2F7EED',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            updateLoader: state => state.clientPortalModule.updateLoader,
            clientPortal: state => state.clientPortalModule.clientPortal,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    has_facebook: vm.clientPortal.has_facebook ? vm.clientPortal.has_facebook : 0,
                    has_twitter: vm.clientPortal.has_twitter ? vm.clientPortal.has_twitter : 0,
                    has_instagram: vm.clientPortal.has_instagram ? vm.clientPortal.has_instagram : 0,
                    display_footer: vm.clientPortal.display_footer ? vm.clientPortal.display_footer : 0,
                    footer_facebook: vm.clientPortal.footer_facebook ? vm.clientPortal.footer_facebook : '',
                    footer_twitter: vm.clientPortal.footer_twitter ? vm.clientPortal.footer_twitter : '',
                    footer_instagram: vm.clientPortal.footer_instagram ? vm.clientPortal.footer_instagram : '',
                    footer_company: vm.clientPortal.footer_company ? vm.clientPortal.footer_company : '',
                    footer_terms: vm.clientPortal.footer_terms ? vm.clientPortal.footer_terms : '',
                    footer_policy: vm.clientPortal.footer_policy ? vm.clientPortal.footer_policy : '',
                    footer_textcolor: vm.clientPortal.footer_textcolor ? vm.clientPortal.footer_textcolor : '#2F7FED',
                    footer_bgcolor: vm.clientPortal.footer_bgcolor ? vm.clientPortal.footer_bgcolor : '#f5f5f5',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePortalSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    :deep(.playbook-header-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.playbook-header-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
</style>
